.experience-main {
  width: 100%;
}

.basic-experience {
  margin-left: 5%;
  margin-right: 5%;
}

.experience-heading-div {
  display: flex;
}

.experience-heading-div > * {
  flex: 1;
}

.experience-heading-img-div {
  align-items: center;
  justify-content: center;
}

.experience-heading-text-div {
  text-align: center;
}

.experience-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.experience-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 80px;
}

.experience-heading-sub-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-bottom: 10px;
}

.experience-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

@media (max-width: 1380px) {
  .experience-heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .experience-heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .experience-heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .experience-heading-sub-text {
    font-size: 20px;
  }

  .experience-heading-div {
    flex-direction: column;
  }

  .experience-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
}



@media (max-width: 550px) {


  .logo-cpa-edu{
    width: 100px;
  }

  .card-head {
    font-size: 12px;
  
}
.right-h-edu>p {
  font-size: 13px;

}
.btn-shop-heavy {

  padding: 5px 14px;

  font-size: 15px;

}
.btn-shop-fit {
  padding: 5px 14px;

  font-size: 15px;
}

.cpa-card {
  padding: 50px 25px;
}
.main-div-edu {

  padding: 50px 0px;

}
}



.btn-shop-fit{
  background: #26959B;
  outline: none;
  padding: 15px 41px;
  border-radius: 5px;
  border: 0;
  font-size: 20px;
  color: white;
  cursor: pointer;
  outline: 0!important;
  cursor: pointer;
}

.btn-shop-heavy{
  background:#FFA800;

  outline: none;
  padding: 15px 41px;
  border-radius: 5px;
  border: 0;
  font-size: 20px;
  color: white;
  cursor: pointer;
  outline: 0!important;
  cursor: pointer;
}