.education-main {
  width: 100%;
}

.basic-education {
  margin-left: 5%;
  margin-right: 5%;
}

.heading-div {
  display: flex;
}

.heading-div > * {
  flex: 1;
}

.heading-img-div {
  align-items: center;
  justify-content: center;
}

.heading-text-div {
  text-align: center;
}

.heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 100px;
}

.heading-sub-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
}

@media (max-width: 1380px) {
  .heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .heading-sub-text {
    font-size: 20px;
  }

  .heading-div {
    flex-direction: column;
  }
}


.container {
  display: flex;
  height: 70vh;
}

.section {
  text-decoration: none;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  color: #fff;
  transition: flex .4s ease;
  position: relative;
}

.section .overlay {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  transition: background-color .8s ease;
}

.section .content {
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.section:hover {
  flex: 2;
}

.section:hover .overlay {
  background-color: rgba(0, 0, 0, 0.95);
}

#marketing {
  background-image: url(https://images.unsplash.com/photo-1522205987242-8e22924ab42a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=c0f679eb8f15705d46ea90008f39642b&auto=format&fit=crop&w=500&q=60);
}


#technology {
  background-image: url('../../assests/cpa.jpg');
}

#advertising {
  background-image: url('../../assests/tuff.jpg');
}


#development {
  background-image: url('../../assests/fithuman.jpg');
}

#coding {
  background-image: url('../../assests/teamheavy.jpg');
}
.logo-cpa-edu{
  width: 300px;
}
.main-div-edu {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 100px 0px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.right-h-edu {

  display: flex;
  flex-direction: column;
  align-items: center;
}
.right-h-edu>p {
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
}
.btn-shop-cpa{
  background: #bc0000;
  outline: none;
  padding: 15px 41px;
  border-radius: 5px;
  border: 0;
  font-size: 20px;
  color: white;
  cursor: pointer;
  outline: 0!important;
  cursor: pointer;
}
.a-tab-nav{

    text-decoration: none;
    color: 
    white;

}
.btn-shop-tuff{
  background: #0072bb;

  outline: none;
  padding: 15px 41px;
  border-radius: 5px;
  border: 0;
  font-size: 20px;
  color: white;
  cursor: pointer;
  outline: 0!important;
  cursor: pointer;
}

.tuff-card{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding:  50px 25px ;

  width: 40%;

}
.cpa-card {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding:  50px 25px ;
width: 40%;
}
.card-head{
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
}

@media (max-width: 1050px) {

  .tuff-card{

  
    width: 80%;
  
  }
  .cpa-card {

  width: 80%;
  }
  .main-div-edu {

    gap: 25px;
}


}

@media (max-width: 550px) {


  .logo-cpa-edu{
    width: 100px;
  }

  .card-head {
    font-size: 12px;
  
}
.right-h-edu>p {
  font-size: 13px;

}
.btn-shop-cpa {

  padding: 5px 14px;

  font-size: 15px;

}
.btn-shop-tuff {
  padding: 5px 14px;

  font-size: 15px;
}

.cpa-card {
  padding: 50px 25px;
}
.main-div-edu {

  padding: 50px 0px;

}
}