.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Google Sans Regular";
}



.footer-1 {
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Google Sans Regular";
  color: white;
  text-decoration: none;
}


.footer-div {
  margin-top: 2rem;
}
